<template>
 <div class="guidedialogContent">
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" title="引导人员统计" width="8.87rem">
   <div class="dialog-table">
    <div class="dialog-table-th">
     <span :key="index" v-for="(item, index) in tableTilteArr">{{item.title}}</span>
    </div>
    <div :key="index" class="dialog-table-td" v-for="(item, index) in tableContentArr">
     <span>{{item.uname}}</span>
     <span>{{item.columns1}}</span>
     <span>{{item.columns2}}</span>
     <span>{{item.columns3}}</span>
     <span>{{item.columns4}}</span>
     <span>{{item.columns5}}</span>
     <span>{{item.columns6}}</span>
     <span>{{item.columns7}}</span>
     <span>{{item.columns8}}</span>
     <span>{{item.total}}</span>
    </div>
   </div>
   <div class="row buttons">
    <el-button @click="closeDialog" class="bluebtn" type="primary">
     <i class="el-icon-download"></i>导出Excel
    </el-button>
   </div>
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   dialogVisible: false,
   tableTilteArr: [
    {
     title: '引导人员',
    },
    {
     title: '记忆碎片',
    },
    {
     title: '黑暗之夜',
    },
    {
     title: '柯南的快择',
    },
    {
     title: '奇幻大冒险',
    },
    {
     title: 'EVA',
    },
    {
     title: '绝命逃亡',
    },
    {
     title: '哆啦A梦',
    },
    {
     title: '机器姬',
    },
    {
     title: '引导合计',
    },
   ],
   tableContentArr: [
    {
     uname: '胡瑞',
     columns1: 51,
     columns2: 34,
     columns3: 55,
     columns4: 6,
     columns5: 78,
     columns6: 55,
     columns7: 2,
     columns8: 9,
     total: 209,
    },
    {
     uname: '胡瑞',
     columns1: 51,
     columns2: 34,
     columns3: 55,
     columns4: 6,
     columns5: 78,
     columns6: 55,
     columns7: 2,
     columns8: 9,
     total: 209,
    },
    {
     uname: '胡瑞',
     columns1: 51,
     columns2: 34,
     columns3: 55,
     columns4: 6,
     columns5: 78,
     columns6: 55,
     columns7: 2,
     columns8: 9,
     total: 209,
    },
    {
     uname: '胡瑞',
     columns1: 51,
     columns2: 34,
     columns3: 55,
     columns4: 6,
     columns5: 78,
     columns6: 55,
     columns7: 2,
     columns8: 9,
     total: 209,
    },
   ],
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
  },
  showDialog(title = '主题详情', data = []) {
   this.tit = title
   this.dialogVisible = true
   // this.tableContentArr = data; [待数据补全]
  },
 },
}
</script>
<style lang="less">
.dialogContent {
 border-radius: 5px;
 box-sizing: border-box;

 .buttons {
  text-align: center;
  margin-bottom: 0;
  width: 1.36rem;
  margin: 0.35rem auto;
  box-sizing: border-box;
 }
}
</style>
<style lang="less">
// 弹出框为表格数据， 不使用公共弹框样式。。
.guidedialogContent {
 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 8.87rem 0.4rem;
  width: 8.87rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 30px;
    line-height: 25px;
   }
  }
 }

 .el-dialog__body {
  padding: 0.4rem 0.4rem 0 0.4rem;
  height: 5.26rem;
  width: 8.87rem;
  box-sizing: border-box;
  text-align: center;

  .bluebtn {
   background: #208def;
   color: #fff;
   margin: 40px auto;
   border: none;
   width: 1.4rem;
   height: 0.4rem;
   border-radius: 6px;
  }
  .dialog-table {
   // margin-top: 20px;
   border: 1px solid rgba(177, 177, 177, 1);
   border-radius: 6px;
   .dialog-table-th {
    display: flex;
    background-color: #f3f3f3;
    height: 0.57rem;
    line-height: 0.57rem;
    font-size: 1.1em;
    justify-content: space-around;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    span {
     color: #414141;
     width: 1.5rem;
     text-align: center;

     &:nth-of-type(7) {
      width: 2rem;
     }
    }
   }

   .dialog-table-td {
    display: flex;
    align-items: center;
    background-color: rgba(243, 243, 243, 1);
    justify-content: space-between;
    font-size: 1.143em;
    height: 0.78rem;

    span {
     color: #b1b1b1;
     width: 1.5rem;
     padding: 0.3rem 0;
     text-align: center;
     box-sizing: border-box;

     &:nth-of-type(7) {
      width: 2rem;
     }
    }
   }

   .dialog-table-td:nth-of-type(2n) {
    background-color: rgba(255, 255, 255, 1);
   }

   .dialog-table-td:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
   }
  }
 }
}
</style>
